import axios from "axios";
import store from "../store";
import authToken from "../common/authToken";
import router from "../router";
import { ROUTER_URL } from "../constants/urls";
// import Keys from "../constants/keys";

let CancelToken = axios.CancelToken;
let source = CancelToken.source();

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";

export const AxiosHelper = {
  handle440: (statusCode) => {
    if (statusCode === 440) {
      store.dispatch("snackBar/showToast", {
        message: "PublicSession Expired",
        color: "E",
      });
      localStorage.removeItem("token");
      router.push(ROUTER_URL.auth.children.login.link);
      window.location.href = window.urls.logout;
    }
  },
  handle403: (statusCode) => {
    if (statusCode === 403) {
      store.dispatch("snackBar/showToast", {
        message: "Invalid token",
        color: "E",
      });

      localStorage.removeItem("token");
      router.push(ROUTER_URL.auth.children.login.link);
    }
  },
  handle400: (data) => {
    if (data.status === 400) {
      store.dispatch("snackBar/showToast", {
        message: data.data.message,
        color: "E",
      });
    }
  },
  handle404: (statusCode) => {
    if (statusCode === 404) {
      store.dispatch("snackBar/showToast", {
        message: "Invalid API URL",
        color: "E",
      });
    }
  },
  errorHandler: (response) => {
    switch (response.status) {
      case 400:
        store.dispatch("snackBar/showToast", {
          message: response.data.message,
          color: "E",
        });
        break;
      case 401:
        store.dispatch("snackBar/showToast", {
          message: response.data.message,
          color: "E",
        });
        break;
      case 403:
        store.dispatch("snackBar/showToast", {
          message: "Invalid password",
          color: "E",
        });
        localStorage.removeItem("token");
        localStorage.removeItem("user_type");
        router.push(ROUTER_URL.auth.children.login.link);
        break;
      case 404:
        if (
          response.data.message != "undefined" &&
          response.data.message != null
        ) {
          store.dispatch("snackBar/showToast", {
            message: response.data.message,
            color: "E",
          });
        } else {
          store.dispatch("snackBar/showToast", {
            message: "Invalid API URL",
            color: "E",
          });
        }
        break;
      case 440:
        store.dispatch("snackBar/showToast", {
          message: "PublicSession Expired",
          color: "E",
        });
        localStorage.removeItem("token");
        // localStorage.removeItem("user_type");
        router.push(ROUTER_URL.auth.children.login.link);
        break;
      default:
        if (response.data.message) {
          store.dispatch("snackBar/showToast", {
            message: response.data.message,
            color: "E",
          });
        } else {
          store.dispatch("snackBar/showToast", {
            message: "Something Went Wrong!",
            color: "E",
          });
        }
    }
  },
  updatePermissions: (response) => {
    if (
      response.data.permissions !== null &&
      response.data.permissions !== "" &&
      response.data.permissions !== undefined
    ) {
      store.dispatch("mainStore/updatePermissions", response.data.permissions);
    }
  },
};

const Axios = {
  request_GET: (
    url,
    params,
    headers,
    fnSuccessResponse = null,
    fnFailureResponse = null,
    cancel = true,
    isTokenRequired = true,
    fnFinallyBlock = null
  ) => {
    if (cancel) {
      source.cancel();
      source = axios.CancelToken.source();
      headers["cancelToken"] = source.token;
    }

    if (isTokenRequired) {
      headers["token"] = authToken.getAccessToken();
    }

    axios
      .get(url, {
        params: params,
        headers: headers,
        cancelToken: cancel ? source.token : "",
      })
      .then(function (response) {
        if (fnSuccessResponse != null) {
          fnSuccessResponse(response);
        }

        // update token
        if (
          response.headers.token !== null &&
          response.headers.token !== "" &&
          response.headers.token !== undefined &&
          !url.includes("reset-password")
        ) {
          localStorage.setItem("token", response.headers.token);
        }
        AxiosHelper.updatePermissions(response);
      })
      .catch(function (error) {
        if (axios.isCancel(error)) {
          // Request cancelled
        } else {
          if (!error.response) {
            // Network error
            console.log("Caught error in Axios:", error);
            store.dispatch("snackBar/showToast", {
              message: "Something Went Wrong! Try Reloading !!",
              color: "E",
            });
          } else {
            if (fnFailureResponse != null) {
              fnFailureResponse();
            }

            AxiosHelper.errorHandler(error.response);
            if (
              error.response.headers.permissions !== null &&
              error.response.headers.permissions !== "" &&
              error.response.headers.permissions !== undefined
            ) {
              store.dispatch(
                "mainStore/updatePermissions",
                error.response.headers.permissions
              );
            }
          }
        }
      })
      .finally(function () {
        if (fnFinallyBlock != null) {
          fnFinallyBlock();
        }
      });
  },
  request_POST: (
    url,
    params,
    headers,
    fnSuccessResponse = null,
    fnFailureResponse = null,
    cancel = true,
    isTokenRequired = true,
    fnFinallyBlock = null
  ) => {
    var config = {};

    if (cancel) {
      source.cancel();
      source = axios.CancelToken.source();
      config["cancelToken"] = source.token;
    }

    if (!cancel) {
      source = axios.CancelToken.source();
    }

    if (isTokenRequired) {
      headers["token"] = authToken.getAccessToken();
    }

    config["headers"] = headers;

    axios
      .post(url, params, config)
      .then(function (response) {
        if (fnSuccessResponse != null) {
          fnSuccessResponse(response);
        }
        // update token
        // console.log(response.headers.token, "response.headers.token");
        if (
          response.headers.token !== null &&
          response.headers.token !== "" &&
          response.headers.token !== undefined &&
          !url.includes("reset-password")
        ) {
          window.addEventListener("popstate", function () {
            // console.log("Navigated from:", document.referrer);
          });

          // console.log("saving to")
          // localStorage.setItem("token", response.headers.token);
        }
        AxiosHelper.updatePermissions(response);
      })
      .catch(function (error) {
        // console.log(error);
        if (axios.isCancel(error)) {
          // console.log("Request canceled", error);
        } else {
          if (!error.response) {
            // Network error
            store.dispatch("snackBar/showToast", {
              message: "Something Went Wrong! Try Reloading",
              color: "E",
            });
          } else {
            if (fnFailureResponse != null) {
              fnFailureResponse(error.response);
            }

            AxiosHelper.errorHandler(error.response);
            if (
              error.response.headers.permissions !== null &&
              error.response.headers.permissions !== "" &&
              error.response.headers.permissions !== undefined
            ) {
              store.dispatch(
                "mainStore/updatePermissions",
                error.response.headers.permissions
              );
            }
          }
        }
      })
      .finally(function () {
        if (fnFinallyBlock != null) {
          fnFinallyBlock();
        }
      });
  },
  request_DELETE: (
    url,
    params,
    headers,
    fnSuccessResponse = null,
    fnFailureResponse = null,
    cancel = true,
    isTokenRequired = true,
    fnFinallyBlock = null
  ) => {
    if (cancel) {
      source.cancel();
      source = axios.CancelToken.source();
      headers["cancelToken"] = source.token;
    }
    if (isTokenRequired) {
      headers["token"] = authToken.getAccessToken();
    }

    axios
      .delete(url, {
        data: params,
        headers: headers,
        cancelToken: cancel ? source.token : "",
      })
      .then(function (response) {
        if (fnSuccessResponse != null) {
          fnSuccessResponse(response);
        }
        // update token
        if (
          response.headers.token !== null &&
          response.headers.token !== "" &&
          response.headers.token !== undefined
        ) {
          localStorage.setItem("token", response.headers.token);
        }
      })
      .catch(function (error) {
        if (axios.isCancel(error)) {
          // Request cancelled
        } else {
          if (!error.response) {
            // Network error
            store.dispatch("snackBar/showToast", {
              message: "Something Went Wrong! Try Reloading",
              color: "E",
            });
          } else {
            if (fnFailureResponse != null) {
              fnFailureResponse(error.response);
            }

            AxiosHelper.errorHandler(error.response);
            if (
              error.response.headers.permissions !== null &&
              error.response.headers.permissions !== "" &&
              error.response.headers.permissions !== undefined
            ) {
              store.dispatch(
                "mainStore/updatePermissions",
                error.response.headers.permissions
              );
            }
          }
        }
      })
      .finally(function () {
        if (fnFinallyBlock != null) {
          fnFinallyBlock();
        }
      });
  },
  request_PUT: (
    url,
    params,
    headers,
    fnSuccessResponse = null,
    fnFailureResponse = null,
    cancel = true,
    isTokenRequired = true,
    fnFinallyBlock = null
  ) => {
    if (cancel) {
      source.cancel();
      source = axios.CancelToken.source();
    }
    /** COMMENTED ON 9 MAR **/
    if (!cancel) {
      source = axios.CancelToken.source();
    }
    headers["cancelToken"] = source.token;
    if (isTokenRequired) {
      headers["token"] = authToken.getAccessToken();
    }

    axios
      .put(url, params, { headers: headers })
      .then(function (response) {
        if (fnSuccessResponse != null) {
          fnSuccessResponse(response);
        }
        // update token
        if (
          response.headers.token !== null &&
          response.headers.token !== "" &&
          response.headers.token !== undefined
        ) {
          localStorage.setItem("token", response.headers.token);
        }
        AxiosHelper.updatePermissions(response);
      })
      .catch(function (error) {
        if (axios.isCancel(error)) {
          // console.log("Request canceled", error.message);
        } else {
          if (!error.response) {
            // Network error
            store.dispatch("snackBar/showToast", {
              message: "Something Went Wrong! Try Reloading",
              color: "E",
            });
          } else {
            if (fnFailureResponse != null) {
              fnFailureResponse();
            }

            AxiosHelper.errorHandler(error.response);
            if (
              error.response.headers.permissions !== null &&
              error.response.headers.permissions !== "" &&
              error.response.headers.permissions !== undefined
            ) {
              store.dispatch(
                "mainStore/updatePermissions",
                error.response.headers.permissions
              );
            }
          }
        }
      })
      .finally(function () {
        if (fnFinallyBlock != null) {
          fnFinallyBlock();
        }
      });
  },
  request_PATCH: (
    url,
    params,
    headers,
    fnSuccessResponse = null,
    fnFailureResponse = null,
    cancel = true,
    isTokenRequired = true,
    fnFinallyBlock = null
  ) => {
    var config = {};

    if (cancel) {
      source.cancel();
      source = axios.CancelToken.source();
      config["cancelToken"] = source.token;
    }

    if (!cancel) {
      source = axios.CancelToken.source();
    }

    if (isTokenRequired) {
      headers["token"] = authToken.getAccessToken();
    }

    config["headers"] = headers;

    axios
      .patch(url, params, config)
      .then(function (response) {
        if (fnSuccessResponse != null) {
          fnSuccessResponse(response);
        }
        // update token
        if (
          response.headers.token !== null &&
          response.headers.token !== "" &&
          response.headers.token !== undefined
        ) {
          localStorage.setItem("token", response.headers.token);
        }
        AxiosHelper.updatePermissions(response);
      })
      .catch(function (error) {
        if (axios.isCancel(error)) {
          // console.log("Request canceled", error.message);
        } else {
          if (!error.response) {
            // Network error
            store.dispatch("snackBar/showToast", {
              message: "Something Went Wrong! Try Reloading",
              color: "E",
            });
          } else {
            if (fnFailureResponse != null) {
              fnFailureResponse();
            }

            AxiosHelper.errorHandler(error.response);
            if (
              error.response.headers.permissions !== null &&
              error.response.headers.permissions !== "" &&
              error.response.headers.permissions !== undefined
            ) {
              store.dispatch(
                "mainStore/updatePermissions",
                error.response.headers.permissions
              );
            }
          }
        }
      })
      .finally(function () {
        if (fnFinallyBlock != null) {
          fnFinallyBlock();
        }
      });
  },
  request_PASSWORD_RESET: (
    url,
    params,
    headers,
    fnSuccessResponse = null,
    fnFailureResponse = null,
    cancel = true,
    isTokenRequired = false,
    fnFinallyBlock = null
  ) => {
    if (cancel) {
      source.cancel();
      source = axios.CancelToken.source();
    }
    /** COMMENTED ON 9 MAR **/
    if (!cancel) {
      source = axios.CancelToken.source();
    }
    headers["cancelToken"] = source.token;
    if (isTokenRequired) {
      headers["token"] = authToken.getAccessToken();
    }

    axios
      .post(url, params, { headers: headers })
      .then(function (response) {
        if (fnSuccessResponse != null) {
          fnSuccessResponse(response);
        }
        // update token
        if (
          response.headers.token !== null &&
          response.headers.token !== "" &&
          response.headers.token !== undefined
        ) {
          // console.log("saving token password-reset");

          // localStorage.setItem("token", response.headers.token);
        }
        //update permissions
        if (
          response.headers.permissions !== null &&
          response.headers.permissions !== "" &&
          response.headers.permissions !== undefined
        ) {
          store.dispatch(
            "mainStore/updatePermissions",
            response.headers.permissions
          );
        }
      })
      .catch(function (error) {
        if (axios.isCancel(error)) {
          // console.log("Request canceled", error.message);
        } else {
          if (!error.response) {
            // Network error
            store.dispatch("snackBar/showToast", {
              message: "Something Went Wrong! Try Reloading",
              color: "E",
            });
          } else {
            if (fnFailureResponse != null) {
              fnFailureResponse();
            }

            AxiosHelper.errorHandler(error.response);
            if (
              error.response.headers.permissions !== null &&
              error.response.headers.permissions !== "" &&
              error.response.headers.permissions !== undefined
            ) {
              store.dispatch(
                "mainStore/updatePermissions",
                error.response.headers.permissions
              );
            }
          }
        }
      })
      .finally(function () {
        if (fnFinallyBlock != null) {
          fnFinallyBlock();
        }
      });
  },
  request_DOWNLOAD: (
    url,
    params,
    headers,
    fnSuccessResponse = null,
    fnFailureResponse = null,
    filename,
    cancel = false,
    isTokenRequired = true
  ) => {
    if (cancel) {
      source.cancel();
      source = axios.CancelToken.source();
      headers["cancelToken"] = source.token;
    }

    if (isTokenRequired) {
      headers["token"] = authToken.getAccessToken();
    }

    // headers["responseType"] = "blob";
    // responseType:
    axios
      .get(url, {
        params: params,
        headers: headers,
        responseType: "blob",
        cancelToken: cancel ? source.token : "",
      })
      .then((response) => {
        if (fnSuccessResponse != null) {
          fnSuccessResponse(response);
        }
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
      })
      .catch(function (error) {
        if (fnFailureResponse != null) {
          fnFailureResponse();
        }

        AxiosHelper.errorHandler(error.response);
      });
  },
  request_GET_BLOB: (
    url,
    params,
    headers,
    fnSuccessResponse = null,
    fnFailureResponse = null,
    cancel = true,
    isTokenRequired = true,
    isBlobType = false,
    fnFinallyBlock = null
  ) => {
    if (cancel) {
      source.cancel();
      source = axios.CancelToken.source();
      headers["cancelToken"] = source.token;
    }

    if (isTokenRequired) {
      headers["token"] = authToken.getAccessToken();
    }

    axios
    .get(url, {
      params: params,
      headers: headers,
      cancelToken: cancel ? source.token : "",
      responseType: isBlobType ? "blob" : "", // 🔹 Response might be a Blob
    })
    .then(function (response) {
      if (fnSuccessResponse) {
        fnSuccessResponse(response);
      }
    })
    .catch(async function (error) {
      console.error("Axios Error:", error); // 🔹 Debugging
  
      if (axios.isCancel(error)) {
        console.log("Request was cancelled.");
        return;
      }
  
      if (!error.response) {
        console.log("Network error or no response from server.");
        store.dispatch("snackBar/showToast", {
          message: "Something Went Wrong! Try Reloading",
          color: "E",
        });
        return;
      }
  
      let errorMessage = "Bad Request"; // Default message
  
      // 🔹 If response is a blob, convert it to JSON
      if (error.response.data instanceof Blob) {
        try {
          const errorData = await error.response.data.text(); // Convert Blob to text
          const jsonError = JSON.parse(errorData); // Parse JSON
          errorMessage = jsonError?.message || errorMessage; // Extract message
        } catch (parseError) {
          console.error("Error parsing blob response:", parseError);
        }
      } else {
        // 🔹 Normal JSON response
        errorMessage = error.response.data?.message || error.response.statusText || "No campaign";
      }
  
      console.log("Error Status:", error.response.status);
      console.log("Error Message:", errorMessage);
  
      // 🔹 Show error in Snackbar
      store.dispatch("snackBar/showToast", {
        message: errorMessage,
        color: "E",
      });
  
      if (fnFailureResponse) {
        fnFailureResponse(error);
      }
    })
    .finally(function () {
      if (fnFinallyBlock) {
        fnFinallyBlock();
      }
    });
  
  },
};

export default Axios;
